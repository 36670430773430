import React from "react"
import { Box, Heading, Text, useColorModeValue } from "@chakra-ui/react"
import Layout from "../../components/Layout"
import Meta from "../../components/Meta"

const ThankYou = ({ data }) => {
  return (
    <Layout>
      <Meta
        title="Bullseye Thank You"
        description="Thank you for filling out our form. Bullseye Lawn Care will follow up with you soon"
      />
      <Box as="section" bg={useColorModeValue("gray.50", "gray.700")}>
        <Box
          maxW="2xl"
          mx="auto"
          px={{ base: "6", lg: "8" }}
          py={{ base: "16", sm: "20" }}
          textAlign="center"
        >
          <Heading
            as="h2"
            size="2xl"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            Thank you!
          </Heading>
          <Text mt="4" fontSize="lg">
            Bullseye Lawn Care will contact you soon. Feel free to call us if
            you have any questions in the meantime.
          </Text>
          <Text
            mt="4"
            fontSize="2xl"
            color={useColorModeValue("orange.500", "orange.400")}
          >
            (605) 204-5458
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default ThankYou
